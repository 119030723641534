import PropTypes from "prop-types";
import React from "react";
import { Typography } from "antd";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Img from "gatsby-image";
const SuccessPage = props => {
  const {
    data: { file }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Contacto" theme={theme} />
              <Typography theme={theme} style={{ color: "white", margin: "2em 0" }}>
                Gracias por contactarnos. <br />
                Responderemos a tu mensaje tan pronto como sea posible.
              </Typography>
              <Img fluid={file.childImageSharp.fluid} />
            </header>
          </Article>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

SuccessPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default SuccessPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query {
    file(relativePath: { eq: "pinguinos.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
